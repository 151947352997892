import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/nprogress/nprogress.css");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/app/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/swiper.min.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/modules/navigation/navigation.min.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/vanilla-cookieconsent/dist/cookieconsent.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/_dispatcher/AuthDispatcher.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/assets/css/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/Components/Modals/Containers/BaseModalContainer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DialogModalContainer"] */ "/app/src/lib/hooks/useDialogModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SecondaryDialogModalContainer"] */ "/app/src/lib/hooks/useSecondaryDialogModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/src/shadcn/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/shared/hocs/ReduxProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/shared/hocs/ThemeProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/utils/clarityUtils.tsx");
